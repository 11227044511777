module.exports = {
  title: "Triandi - Front End Enthusiast",
  description: " Tersedia : Portofolio Front End yang menarik. Jelajahi karya-karya saya untuk melihat keahlian dalam menciptakan pengalaman web yang menawan.",
  keywords: "triandi, url triandi, web triandi, coding, front end, triandii, triandi umrah, universitas maritim raja ali haji",
  url: "https://triandi.wsus.me",
  twitter: '@triandii',
  ogImage: "https://storage.wsus.me/uploads/biolink/andi.jpg",
  seoImage: "https://storage.wsus.me/uploads/biolink/andi.jpg",
  color: "#000000",
};
